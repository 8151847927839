import cn from 'classnames/bind'
import { ReactElement, useRef, useState } from 'react'
import { CaretDownFill } from '../svg/CaretDownFill'
import styles from './index.module.css'

const cx = cn.bind(styles)

type Props = {
  children: ReactElement
  title: string
}

export const Accordion = ({ children, title }: Props) => {
  const [open, setOpen] = useState(false)
  const ref = useRef<HTMLDivElement>(null)

  const contentHeight =
    ref.current !== null ? (ref.current.firstChild as HTMLUnknownElement).offsetHeight : null

  const toggleOpen = () => {
    setOpen((value) => !value)
  }

  return (
    <div className={cx('accordion')}>
      <button className={cx('accordion-trigger')} onClick={toggleOpen}>
        {title}
        <div className={cx('accortion-trigger-icon', { 'accortion-trigger-icon--rotate': !open })}>
          <CaretDownFill width={16} height={16} />
        </div>
      </button>

      <div
        ref={ref}
        className={cx('accordion-content')}
        style={{ maxHeight: open && contentHeight !== null ? `${contentHeight}px` : '0' }}
      >
        {children}
      </div>
    </div>
  )
}
