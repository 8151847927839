import cn from 'classnames/bind'
import { useEffect, useMemo } from 'react'
import { useNavigate } from 'react-router'
import { NavLink } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { Formik, Form, Field, ErrorMessage } from 'formik'
import * as Yup from 'yup'
import { Button } from '../../components/Button'
import { Card } from '../../components/Card'
import { FormLabel } from '../../components/FormLabel'
import { FormikInput } from '../../components/FormikInput'
import { InputErrorMessage } from '../../components/InputErrorMessage'
import { useLoginMutation } from '../../services/common'
import { useAppDispatch, useAppSelector } from '../../store'
import { addAlert } from '../../store/alerts'
import { login, selectIsLoggedIn } from '../../store/auth'
import { parseAuthToken } from '../../utils/parseAuthToken'
import styles from './index.module.css'

const cx = cn.bind(styles)

const allowedRoles = ['ROLE_USER', 'ROLE_WRITER']
const isValidRole = (roles: string[]) => allowedRoles.some((role) => roles.includes(role))

const initialValues = { email: '', password: '' }

type FormValues = typeof initialValues

export const Login = () => {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const { t } = useTranslation()
  const [loginRequest] = useLoginMutation()
  const isLoggedIn = useAppSelector(selectIsLoggedIn)

  const validationSchema = useMemo(
    () =>
      Yup.object({
        email: Yup.string()
          .email(t('pages.login.fields.email.validation.email'))
          .max(255)
          .required(t('pages.login.fields.email.validation.required')),
        password: Yup.string()
          .max(255)
          .required(t('pages.login.fields.password.validation.required')),
      }),
    [t],
  )

  useEffect(() => {
    if (isLoggedIn) {
      navigate('/')
    }
  }, [navigate, isLoggedIn])

  const handleSubmit = async (values: FormValues) => {
    const response = await loginRequest(values)

    if ('error' in response) {
      const { error } = response
      const alert =
        'status' in error && error.status === 401
          ? ({ type: 'error', translationId: 'feedback.error.invalidCredentials' } as const)
          : ({ type: 'error', translationId: 'feedback.error.generic' } as const)

      dispatch(addAlert(alert))
    }

    if ('data' in response) {
      const { token } = response.data
      const decodedToken = parseAuthToken(token)

      if (!decodedToken || !isValidRole(decodedToken.roles['nsofacile.it'])) {
        dispatch(addAlert({ type: 'error', translationId: 'feedback.error.generic' }))

        return
      }

      dispatch(login({ token }))
      dispatch(addAlert({ type: 'success', translationId: 'feedback.success.successfulLogin' }))

      navigate('/')
    }
  }

  return (
    <div className={cx('container')}>
      <Card>
        <Card.Body>
          <div className={cx('header')}>
            <h2>{t('pages.login.title')}</h2>
            <p>{t('pages.login.subtitle')}</p>
          </div>

          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
          >
            {({ isSubmitting }) => (
              <Form>
                <div className={cx('form-field')}>
                  <FormLabel htmlFor="email" title={t('pages.login.fields.email.label')} />
                  <Field
                    component={FormikInput}
                    type="email"
                    name="email"
                    id="email"
                    placeholder={t('pages.login.fields.email.placeholder')}
                    aria-label={t('pages.login.fields.email.placeholder')}
                  />
                  <ErrorMessage name="email" component={InputErrorMessage} />
                </div>

                <div className={cx('form-field')}>
                  <FormLabel htmlFor="password" title={t('pages.login.fields.password.label')} />
                  <Field
                    component={FormikInput}
                    type="password"
                    name="password"
                    id="password"
                    placeholder={t('pages.login.fields.password.placeholder')}
                    aria-label={t('pages.login.fields.password.placeholder')}
                  />
                  <ErrorMessage name="password" component={InputErrorMessage} />

                  <div className={cx('reset-password')}>
                    <NavLink className={cx('reset-password-link')} to="/reset-password">
                      {t('pages.login.reset_password_link')}
                    </NavLink>
                  </div>
                </div>

                <div className={cx('form-action')}>
                  <Button kind="primary" size="lg" type="submit" disabled={isSubmitting}>
                    {t('pages.login.cta')}
                  </Button>
                </div>
              </Form>
            )}
          </Formik>
        </Card.Body>
      </Card>
    </div>
  )
}
