import cn from 'classnames/bind'
import { ErrorMessage, Field, Form, Formik } from 'formik'
import { useMemo, useState } from 'react'
import { useTranslation, Trans } from 'react-i18next'
import { NavLink } from 'react-router-dom'
import * as Yup from 'yup'
import { Button } from '../../components/Button'
import { Card } from '../../components/Card'
import { FormikInput } from '../../components/FormikInput'
import { FormLabel } from '../../components/FormLabel'
import { InputErrorMessage } from '../../components/InputErrorMessage'
import { useAskResetPasswordMutation } from '../../services/common'
import { useAppDispatch } from '../../store'
import { addAlert } from '../../store/alerts'
import styles from './index.module.css'

const cx = cn.bind(styles)

const initialValues = { email: '' }

type FormValues = typeof initialValues

export const ResetPassword = () => {
  const dispatch = useAppDispatch()
  const { t } = useTranslation()
  const [emailSentTo, setEmailSentTo] = useState<string | null>(null)
  const [askResetPassword] = useAskResetPasswordMutation()

  const validationSchema = useMemo(
    () =>
      Yup.object({
        email: Yup.string()
          .email(t('pages.login.fields.email.validation.email'))
          .max(255)
          .required(t('pages.login.fields.email.validation.required')),
      }),
    [t],
  )

  const handleSubmit = async (values: FormValues) => {
    const response = await askResetPassword(values)

    if ('error' in response) {
      dispatch(
        addAlert({
          type: 'error',
          translationId: 'feedback.error.generic',
        }),
      )
      return
    }

    setEmailSentTo(values.email)
  }

  return (
    <div className={cx('container')}>
      <Card>
        <Card.Body>
          <div className={cx('header')}>
            <h2>{t('pages.reset_password.title')}</h2>
            {emailSentTo == null && <p>{t('pages.reset_password.subtitle')}</p>}
            {emailSentTo != null && (
              <>
                <p>
                  <Trans
                    i18nKey="pages.reset_password.email_sent"
                    values={{ email: emailSentTo }}
                  />
                </p>
                <p>
                  <NavLink className={cx('reset-password-link')} to="/login">
                    {t('pages.reset_password.back_to_login')}
                  </NavLink>
                </p>
              </>
            )}
          </div>

          {emailSentTo == null && (
            <div className={cx('form')}>
              <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={handleSubmit}
              >
                {({ isSubmitting }) => (
                  <Form>
                    <div className={cx('form-field')}>
                      <FormLabel
                        htmlFor="email"
                        title={t('pages.reset_password.fields.email.label')}
                      />
                      <Field
                        component={FormikInput}
                        type="email"
                        name="email"
                        id="email"
                        placeholder={t('pages.reset_password.fields.email.placeholder')}
                        aria-label={t('pages.reset_password.fields.email.placeholder')}
                      />
                      <ErrorMessage name="email" component={InputErrorMessage} />
                    </div>

                    <div className={cx('form-action')}>
                      <Button kind="primary" size="lg" type="submit" disabled={isSubmitting}>
                        {t('pages.reset_password.cta')}
                      </Button>
                    </div>
                  </Form>
                )}
              </Formik>
            </div>
          )}
        </Card.Body>
      </Card>
    </div>
  )
}
