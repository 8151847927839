import { createApi } from '@reduxjs/toolkit/query/react'
import { baseQuery } from '..'
import { buildSearchParams } from '../../utils/buildSearchParams'

type Direction = 'incoming' | 'outgoing'

type HydraResponse<T> = {
  'hydra:totalItems': number
  'hydra:member': T[]
}

type GetLegalEntitiesResponse = HydraResponse<{
  uuid: string
  registeredName: string
  identifierScheme: 'iso6523-actorid-upis'
  identifierValue: string
  smpEnabled: true
  createdAt: string
  updatedAt: string
}>

type GetOrdersResponse = HydraResponse<{
  uuid: string
  createdAt: string
  date: string
  number: string
  processType: string | null
  peppolMessage: {
    createdAt: string
    errorCode: string | null
    requestSentAt: string
    responseReceivedAt: string
    success: boolean
    uuid: string
  }
  recipient: {
    identifier: string | null
    name: string
  }
  sender: {
    identifier: string | null
    name: string
  }
}>

type GetPrintableOrderResponse = {
  url: string
}

type SortOrder = 'asc' | 'desc'

// https://peppol.api.acubeapi.com/docs.html#operation/getOrderCollection
type GetOrdersParams = {
  'page'?: number
  'itemsPerPage'?: number
  'createdAt[before]'?: string
  'createdAt[strictly_before]'?: string
  'createdAt[after]'?: string
  'createdAt[strictly_after]'?: string
  'createdAt'?: string
  'direction'?: Direction
  'documentDate[before]'?: string
  'documentDate[strictly_before]'?: string
  'documentDate[after]'?: string
  'documentDate[strictly_after]'?: string
  'documentNumber'?: string
  '~senderName'?: string | string[]
  'senderId'?: string | string[]
  'recipientName'?: string | string[]
  'recipientId'?: string | string[]
  'sortBy[createdAt]'?: SortOrder
  'sortBy[documentDate]'?: SortOrder
  'sortBy[senderName]'?: SortOrder
  'sortBy[recipientName]'?: SortOrder
}

export const peppolApi = createApi({
  reducerPath: 'peppolApi',
  baseQuery: baseQuery({ baseUrl: process.env.REACT_APP_PEPPOL_BASE_URL as string }),
  endpoints: (builder) => ({
    getLegalEntities: builder.query<GetLegalEntitiesResponse, undefined>({
      query: () => ({
        url: '/legal-entities',
      }),
    }),

    getOrders: builder.query<GetOrdersResponse, GetOrdersParams>({
      query: (params) => ({
        url: `/it/orders${buildSearchParams(params)}`,
      }),
    }),

    getPrintableOrder: builder.query<
      GetPrintableOrderResponse,
      { uuid: string; fileType: 'text/html' | 'application/pdf' }
    >({
      query: ({ uuid, fileType }) => ({
        url: `/it/orders/${uuid}/print`,
        headers: {
          'Accept': fileType,
          'X-WithRedirect': 'false',
        },
        redirect: 'manual',
      }),
      keepUnusedDataFor: 0,
    }),
  }),
})

export const { useGetLegalEntitiesQuery, useGetOrdersQuery, useGetPrintableOrderQuery } = peppolApi
