import cn from 'classnames/bind'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { Card } from '../../components/Card'
import { Spinner } from '../../components/Spinner'
import { useGetUserQuery } from '../../services/common'
import { EditProfileForm } from './Form'
import styles from './index.module.css'

const cx = cn.bind(styles)

export const EditProfile = () => {
  const { t } = useTranslation()

  const { data, error, isFetching } = useGetUserQuery({ id: 'me' })

  const user = useMemo(() => {
    if (data === undefined) {
      return null
    }

    return {
      fullname: data.fullname,
      invoicing_vat_number: data.invoicing_vat_number,
      invoicing_fiscal_id: data.invoicing_fiscal_id,
      invoicing_address: data.invoicing_address,
      invoicing_city: data.invoicing_city,
      invoicing_province: data.invoicing_province,
      invoicing_cap: data.invoicing_cap,
      invoicing_name: data.invoicing_name,
    }
  }, [data])

  return (
    <>
      <Card.Header>
        <Card.HeaderTitle>{t('pages.editProfile.title')}</Card.HeaderTitle>
      </Card.Header>

      <Card.Body>
        {isFetching && (
          <div className={cx('content-loading')}>
            <Spinner width={30} height={30} />
          </div>
        )}

        {error && <div className={cx('content-error')}>{t('feedback.error.generic')}</div>}

        {user !== null && <EditProfileForm user={user} />}
      </Card.Body>
    </>
  )
}
