import { base64Decode } from '../base64'

type AuthToken = {
  exp: string
  roles: {
    'nsofacile.it': string[]
  }
}

/**
 * Returns the decoded JWT token.
 * NB: it does not validate the token against the hash, this should be done
 * by the server.
 */
export const parseAuthToken = (token: string | null) => {
  try {
    if (token !== null) {
      return JSON.parse(base64Decode(token.split('.')[1])) as AuthToken
    }

    return null
  } catch (err) {
    return null
  }
}
