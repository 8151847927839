type QueryParams = Partial<{
  [key: string]: number | string | string[]
}>

export const buildSearchParams = (params: QueryParams) => {
  const searchParams = Object.entries(params).reduce((acc, [key, value]) => {
    const separator = acc !== '' ? '&' : ''

    if ((typeof value === 'string' && value !== '') || typeof value === 'number') {
      return `${acc}${separator}${key}=${encodeURIComponent(value)}`
    }

    if (Array.isArray(value)) {
      return `${acc}${separator}${key}=${value.map((v) => encodeURIComponent(v)).join(',')}`
    }

    return acc
  }, '')

  return searchParams !== '' ? `?${searchParams}` : ''
}
