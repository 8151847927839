import { useTranslation } from 'react-i18next'
import cn from 'classnames/bind'
import styles from './index.module.css'
import { useState } from 'react'

const cx = cn.bind(styles)

export const Language = () => {
  const [dropdownOpen, setDropdownOpen] = useState(false)
  const { i18n, t } = useTranslation()
  const { language } = i18n

  const toggleDropdownOpen = () => {
    setDropdownOpen((value) => !value)
  }

  const changeLanguage = (lang: string) => {
    i18n.changeLanguage(lang)
    setDropdownOpen(false)
  }

  return (
    <div className={cx('wrapper')}>
      <button className={cx('dropdown-trigger')} onClick={toggleDropdownOpen}>
        <img
          src={language === 'it' ? '/images/flag-it.svg' : '/images/flag-en.svg'}
          alt="logo"
          width={24}
          height={18}
        />
      </button>

      {dropdownOpen && (
        <div className={cx('dropdown')}>
          <button
            className={cx('cta', { 'cta--disabled': language === 'it' })}
            onClick={() => changeLanguage('it')}
          >
            <img src="/images/flag-it.svg" alt="logo" width={24} height={18} />
            {t('global.italian')}
          </button>

          <button
            className={cx('cta', { 'cta--disabled': language === 'en' })}
            onClick={() => changeLanguage('en')}
          >
            <img src="/images/flag-en.svg" alt="logo" width={24} height={18} />
            {t('global.english')}
          </button>
        </div>
      )}
    </div>
  )
}
