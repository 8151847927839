import cn from 'classnames/bind'
import { Formik, Form, Field, ErrorMessage, FormikHelpers } from 'formik'
import { useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import * as Yup from 'yup'
import { Button } from '../../../components/Button'
import { FormikInput } from '../../../components/FormikInput'
import { FormLabel } from '../../../components/FormLabel'
import { InputErrorMessage } from '../../../components/InputErrorMessage'
import { User, useUpdateUserMutation } from '../../../services/common'
import { useAppDispatch } from '../../../store'
import { addAlert } from '../../../store/alerts'
import { mapEmptyStringKeysToNull } from '../../../utils/mapEmptyStringKeysToNull'
import { mapNullKeysToEmptyString } from '../../../utils/mapNullKeysToEmptyString'
import styles from './index.module.css'

const cx = cn.bind(styles)

type FormValues = Omit<User, 'email'>

const fields: {
  name: keyof FormValues
  [key: string]: string
}[] = [
  {
    name: 'fullname',
    labelTranslationId: 'pages.editProfile.fields.fullname.label',
    placeholderTranslationId: 'pages.editProfile.fields.fullname.placeholder',
  },
  {
    name: 'invoicing_vat_number',
    labelTranslationId: 'pages.editProfile.fields.invoicingVatNumber.label',
    placeholderTranslationId: 'pages.editProfile.fields.invoicingVatNumber.placeholder',
  },
  {
    name: 'invoicing_fiscal_id',
    labelTranslationId: 'pages.editProfile.fields.invoicingFiscalId.label',
    placeholderTranslationId: 'pages.editProfile.fields.invoicingFiscalId.placeholder',
  },
  {
    name: 'invoicing_address',
    labelTranslationId: 'pages.editProfile.fields.invoicingAddress.label',
    placeholderTranslationId: 'pages.editProfile.fields.invoicingAddress.placeholder',
  },
  {
    name: 'invoicing_city',
    labelTranslationId: 'pages.editProfile.fields.invoicingCity.label',
    placeholderTranslationId: 'pages.editProfile.fields.invoicingCity.placeholder',
  },
  {
    name: 'invoicing_province',
    labelTranslationId: 'pages.editProfile.fields.invoicingProvince.label',
    placeholderTranslationId: 'pages.editProfile.fields.invoicingProvince.placeholder',
  },
  {
    name: 'invoicing_cap',
    labelTranslationId: 'pages.editProfile.fields.invoicingCap.label',
    placeholderTranslationId: 'pages.editProfile.fields.invoicingCap.placeholder',
  },
  {
    name: 'invoicing_name',
    labelTranslationId: 'pages.editProfile.fields.invoicingName.label',
    placeholderTranslationId: 'pages.editProfile.fields.invoicingName.placeholder',
  },
]

type Props = {
  user: Omit<User, 'email'>
}

export const EditProfileForm = ({ user }: Props) => {
  const dispatch = useAppDispatch()
  const { t } = useTranslation()
  const [updateUser, { isLoading }] = useUpdateUserMutation()
  const [invoicingValidationError, setInvoicingValidationError] = useState(false)

  const initialValues = mapNullKeysToEmptyString(user)

  const validationSchema = useMemo(
    () =>
      Yup.object({
        fullname: Yup.string().required(t('pages.editProfile.fields.fullname.validation.required')),
        invoicing_address: Yup.string().required(
          t('pages.editProfile.fields.invoicingAddress.validation.required'),
        ),
        invoicing_city: Yup.string().required(
          t('pages.editProfile.fields.invoicingCity.validation.required'),
        ),
        invoicing_province: Yup.string().required(
          t('pages.editProfile.fields.invoicingProvince.validation.required'),
        ),
        invoicing_cap: Yup.string().required(
          t('pages.editProfile.fields.invoicingCap.validation.required'),
        ),
        invoicing_name: Yup.string().required(
          t('pages.editProfile.fields.invoicingName.validation.required'),
        ),
      }),
    [t],
  )

  const handleSubmit = async (values: FormValues, { setFieldError }: FormikHelpers<FormValues>) => {
    setInvoicingValidationError(false)

    const { invoicing_fiscal_id, invoicing_vat_number } = values

    if (
      (invoicing_vat_number === '' && invoicing_fiscal_id === '') ||
      (invoicing_vat_number !== '' && invoicing_fiscal_id !== '')
    ) {
      setInvoicingValidationError(true)

      return
    }

    const body = mapEmptyStringKeysToNull(values)

    const response = await updateUser({ id: 'me', ...body })

    if ('error' in response) {
      dispatch(addAlert({ type: 'error', translationId: 'feedback.error.generic' }))
    }

    if ('data' in response) {
      dispatch(
        addAlert({ type: 'success', translationId: 'feedback.success.successfulEditProfile' }),
      )
    }
  }

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      {({ isSubmitting }) => (
        <Form>
          {invoicingValidationError && (
            <div className={cx('form-global-error')}>
              {t('feedback.error.fiscalIdOrVatNumberRequired')}
            </div>
          )}

          <div className={cx('form-control-group')}>
            {fields.map(({ name, labelTranslationId, placeholderTranslationId }) => (
              <div key={name} className={cx('form-control')}>
                <FormLabel htmlFor={name} title={t(labelTranslationId)} />
                <Field
                  component={FormikInput}
                  type="text"
                  name={name}
                  id={name}
                  placeholder={t(placeholderTranslationId)}
                />
                <ErrorMessage name={name} component={InputErrorMessage} />
              </div>
            ))}
          </div>

          <div className={cx('form-actions')}>
            <Button kind="primary" size="lg" type="submit" disabled={isLoading}>
              {t('pages.editProfile.cta')}
            </Button>
          </div>
        </Form>
      )}
    </Formik>
  )
}
