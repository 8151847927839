import cn from 'classnames/bind'
import { useTranslation } from 'react-i18next'
import { useAppSelector } from '../../store'
import { selectAlerts } from '../../store/alerts'
import styles from './index.module.css'

const cx = cn.bind(styles)

export const Alerts = () => {
  const { t } = useTranslation()
  const alerts = useAppSelector(selectAlerts)

  return (
    <div className={cx('wrapper')}>
      {alerts.map(({ type, id, translationId }) => (
        <div
          key={id}
          className={cx('alert', {
            [cx('alert--info')]: type === 'info',
            [cx('alert--error')]: type === 'error',
            [cx('alert--success')]: type === 'success',
          })}
        >
          {t(translationId)}
        </div>
      ))}
    </div>
  )
}
