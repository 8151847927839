import cn from 'classnames/bind'
import { ReactNode } from 'react'
import styles from './index.module.css'

const cx = cn.bind(styles)

type Props = {
  children?: ReactNode
}

export const InputErrorMessage = ({ children }: Props) => (
  <div className={cx('error-msg')}>{children}</div>
)
