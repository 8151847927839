import cn from 'classnames/bind'
import { Outlet } from 'react-router'
import { Header } from './Header'
import styles from './index.module.css'

const cx = cn.bind(styles)

export const BaseLayout = () => {
  return (
    <>
      <Header />

      <main>
        <div className={cx('container')}>
          <Outlet />
        </div>
      </main>
    </>
  )
}
