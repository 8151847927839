import cn from 'classnames/bind'
import styles from './index.module.css'

const cx = cn.bind(styles)

type Props = {
  height: number
  width: number
}

export const Spinner = ({ height, width }: Props) => (
  <div
    className={cx('spinner')}
    style={{ height: `${height}px`, width: `${width}px`, borderWidth: `${width / 10}px` }}
  />
)
