import cn from 'classnames/bind'
import { Outlet } from 'react-router'
import { Card } from '../../components/Card'
import { useAppSelector } from '../../store'
import { SidebarLayoutNavigation } from './Navigation'
import styles from './index.module.css'
import { selectIsMobileMenuVisible } from '../../store/ui'

const cx = cn.bind(styles)

export const SidebarLayout = () => {
  const isMobileMenuVisible = useAppSelector(selectIsMobileMenuVisible)

  return (
    <div className={cx('wrapper')}>
      <div className={cx('sidebar', { 'sidebar--visible': isMobileMenuVisible })}>
        <Card>
          <Card.Body>
            <SidebarLayoutNavigation />
          </Card.Body>
        </Card>
      </div>

      <div className={cx('content')}>
        <Card>
          <Outlet />
        </Card>
      </div>
    </div>
  )
}
