import cn from 'classnames/bind'
import { useMemo } from 'react'
import { batch } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { Formik, Form, Field, FormikHelpers, ErrorMessage } from 'formik'
import * as Yup from 'yup'
import { Button } from '../../../components/Button'
import { InputErrorMessage } from '../../../components/InputErrorMessage'
import { Accordion } from '../../../components/Accordion'
import { useAppDispatch, useAppSelector } from '../../../store'
import {
  selectTableFilters,
  updateFilter,
  resetFilters,
  resetPagination,
} from '../../../store/tables'
import type { TableFilter } from '../../../store/tables'
import { FormLabel } from '../../../components/FormLabel'
import { FormikDateInput } from '../../../components/FormikInputDate'
import { FormikInput } from '../../../components/FormikInput'
import { mapTableFiltersToFormValues, mapFormValuesToTableFilters } from './utils'
import type { FormValues } from './utils'
import styles from './index.module.css'

const cx = cn.bind(styles)

export const IncomingOrdersFilters = () => {
  const dispatch = useAppDispatch()
  const { t } = useTranslation()

  const filters = useAppSelector(selectTableFilters({ table: 'incomingOrders' }))
  const initialValues = mapTableFiltersToFormValues(filters)

  const validationSchema = useMemo(
    () =>
      Yup.object({
        creationYear: Yup.date()
          .typeError(t('pages.incomingOrders.filters.fields.creationYear.validation.year'))
          .min(1970, t('pages.incomingOrders.filters.fields.creationYear.validation.year'))
          .max(2050, t('pages.incomingOrders.filters.fields.creationYear.validation.year')),
      }),
    [t],
  )

  const handleReset = () => {
    dispatch(resetFilters({ table: 'incomingOrders' }))
  }

  const handleSubmit = async (values: FormValues, helpers: FormikHelpers<FormValues>) => {
    const { orderNumber, orderDateAfter, orderDateBefore } = values

    if (orderNumber !== '' && orderDateAfter === '' && orderDateBefore === '') {
      helpers.setFieldError(
        'orderNumber',
        t('pages.incomingOrders.filters.fields.orderNumber.validation.requiresDate'),
      )
      return
    }

    const updatedFilters = mapFormValuesToTableFilters(values)

    batch(() => {
      const filtersChanged = Object.entries(updatedFilters).reduce((acc, [key, value]) => {
        const filter = key as TableFilter
        const filterChanged = value !== filters[filter]

        if (filterChanged) {
          dispatch(updateFilter({ table: 'incomingOrders', filter, value }))
        }

        return acc || filterChanged
      }, false)

      if (filtersChanged) {
        dispatch(resetPagination({ table: 'incomingOrders' }))
      }
    })
  }

  return (
    <Accordion title={t('pages.incomingOrders.filters.title')}>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {({ isSubmitting }) => (
          <Form>
            <div className={cx('form-control-group')}>
              <div className={cx('form-control')}>
                <FormLabel
                  htmlFor="senderName"
                  title={t('pages.incomingOrders.filters.fields.senderName.label')}
                />
                <Field component={FormikInput} type="text" name="senderName" id="senderName" />
              </div>

              <div className={cx('form-control', 'form-control--wrap')}>
                <FormLabel
                  htmlFor="creationYear"
                  title={t('pages.incomingOrders.filters.fields.creationYear.label')}
                />
                <Field component={FormikInput} type="text" name="creationYear" id="creationYear" />
                <ErrorMessage name="creationYear" component={InputErrorMessage} />
              </div>

              <div className={cx('form-control')}>
                <FormLabel
                  htmlFor="orderDateAfter"
                  title={t('pages.incomingOrders.filters.fields.orderDateAfter.label')}
                />
                <Field
                  component={FormikDateInput}
                  type="date"
                  name="orderDateAfter"
                  id="orderDateAfter"
                />
              </div>

              <div className={cx('form-control')}>
                <FormLabel
                  htmlFor="orderDateBefore"
                  title={t('pages.incomingOrders.filters.fields.orderDateBefore.label')}
                />
                <Field
                  component={FormikDateInput}
                  type="date"
                  name="orderDateBefore"
                  id="orderDateBefore"
                />
              </div>

              <div className={cx('form-control')}>
                <FormLabel
                  htmlFor="orderNumber"
                  title={t('pages.incomingOrders.filters.fields.orderNumber.label')}
                />
                <Field component={FormikInput} type="text" name="orderNumber" id="orderNumber" />
              </div>
            </div>

            <ErrorMessage name="orderNumber" component={InputErrorMessage} />

            <div className={cx('form-actions')}>
              <Button kind="primary-inverse" size="sm" onClick={handleReset} type="reset">
                {t('pages.incomingOrders.filters.cta.reset')}
              </Button>

              <Button kind="primary" size="sm" type="submit" disabled={isSubmitting}>
                {t('pages.incomingOrders.filters.cta.submit')}
              </Button>
            </div>
          </Form>
        )}
      </Formik>
    </Accordion>
  )
}
