import { useEffect, useMemo, useState } from 'react'
import { useGetPrintableOrderQuery } from '../../services/peppol'

export const useOrderDetails = (orderUuid: string | null) => {
  const [data, setData] = useState<string | null>(null)
  // the loading state of the 2 requests as a whole
  const [sharedLoading, setSharedLoading] = useState(false)
  // the error state of the 2 requests as a whole
  const [sharedError, setSharedError] = useState(false)

  const {
    data: orderData,
    error: orderError,
    isFetching,
  } = useGetPrintableOrderQuery(
    { uuid: String(orderUuid), fileType: 'text/html' },
    { skip: orderUuid === null },
  )

  useEffect(() => {
    if (isFetching) {
      setSharedLoading(true)
    }
  }, [isFetching])

  useEffect(() => {
    if (orderError !== undefined) {
      setSharedLoading(false)
      setSharedError(true)
    }
  }, [orderError])

  useEffect(() => {
    ;(async () => {
      try {
        if (orderData !== undefined) {
          const response = await fetch(orderData.url, {
            method: 'GET',
            headers: {
              accept: 'text/html',
            },
          })

          setData(await response.text())
        }
      } catch (err) {
        setSharedError(true)
      } finally {
        setSharedLoading(false)
      }
    })()
  }, [orderData])

  return useMemo(
    () => ({
      isFetching: sharedLoading,
      hasError: sharedError,
      data,
    }),
    [sharedLoading, sharedError, data],
  )
}
