import cn from 'classnames/bind'
import { useTranslation } from 'react-i18next'
import { Link, useLocation } from 'react-router-dom'
import { Button } from '../../../components/Button'
import { Hamburger } from '../../../components/svg/Hamburger'
import { useAppDispatch, useAppSelector } from '../../../store'
import { logout, selectIsLoggedIn } from '../../../store/auth'
import { toggleMobileMenu } from '../../../store/ui'
import { Language } from '../Language'
import styles from './index.module.css'

const cx = cn.bind(styles)

export const Header = () => {
  const dispatch = useAppDispatch()
  const { pathname } = useLocation()
  const { t } = useTranslation()

  const loggedIn = useAppSelector(selectIsLoggedIn)

  const toggleMenu = () => {
    dispatch(toggleMobileMenu())
  }

  return (
    <>
      <header className={cx('header')}>
        <div className={cx('header-wrapper')}>
          <nav className={cx('nav')}>
            <Link className={cx('logo')} to="/">
              <img src="/images/logo.svg" alt="logo" width={200} height={32} />
            </Link>

            <Language />

            {loggedIn && (
              <div className={cx('logout')}>
                <Button kind="primary-inverse" size="sm" onClick={() => dispatch(logout())}>
                  {t('global.logout')}
                </Button>
              </div>
            )}

            {!['/login', '/reset-password'].includes(pathname) && (
              <button className={cx('menu-trigger')} onClick={toggleMenu}>
                <Hamburger width={24} height={24} />
              </button>
            )}
          </nav>
        </div>
      </header>

      <div className={cx('navbar')} />
    </>
  )
}
