import { useEffect } from 'react'
import { useNavigate, Outlet } from 'react-router'
import { useAppDispatch, useAppSelector } from '../../store'
import { addAlert } from '../../store/alerts'
import { logout, selectAuthToken, selectIsLoggedIn } from '../../store/auth'

export const AssertAuthenticated = () => {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()

  const token = useAppSelector(selectAuthToken)
  const isLoggedIn = useAppSelector(selectIsLoggedIn)

  useEffect(() => {
    if (!isLoggedIn) {
      if (token !== null) {
        dispatch(addAlert({ type: 'error', translationId: 'feedback.error.sessionExpired' }))
      }

      dispatch(logout())
      navigate('/login')
    }
  }, [dispatch, token, isLoggedIn, navigate])

  if (isLoggedIn) {
    return <Outlet />
  }

  return null
}
