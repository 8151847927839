import { createApi } from '@reduxjs/toolkit/query/react'
import { baseQuery } from '..'

type LoginResponse = { token: string }

export type User = {
  email: string
  fullname: string | null
  invoicing_vat_number: string | null
  invoicing_fiscal_id: string | null
  invoicing_address: string | null
  invoicing_city: string | null
  invoicing_province: string | null
  invoicing_cap: string | null
  invoicing_name: string | null
}

type UpdateUserBody = Partial<Omit<User, 'email'>>

type UpdatePasswordBody = { password: string }

export const commonApi = createApi({
  tagTypes: ['USER'],
  reducerPath: 'commonApi',
  baseQuery: baseQuery({ baseUrl: process.env.REACT_APP_COMMON_BASE_URL as string }),
  endpoints: (builder) => ({
    login: builder.mutation<LoginResponse, { email: string; password: string }>({
      query: (body) => ({
        url: '/login',
        method: 'POST',
        body,
      }),
    }),

    getUser: builder.query<User, { id: string }>({
      query: ({ id }) => ({
        url: `/users/${id}`,
      }),
      providesTags: ['USER'],
    }),

    updateUser: builder.mutation<User, UpdateUserBody & { id: string }>({
      query: ({ id, ...body }) => ({
        url: `/users/${id}`,
        method: 'PUT',
        body,
      }),
      invalidatesTags: ['USER'],
    }),

    updatePassword: builder.mutation<User, UpdatePasswordBody & { id: string }>({
      query: ({ id, ...body }) => ({
        url: `/users/${id}/change-password`,
        method: 'PUT',
        body,
      }),
    }),

    askResetPassword: builder.mutation<void, { email: string }>({
      query: ({ ...body }) => ({
        url: '/ask-reset-password',
        method: 'POST',
        body,
      }),
    }),
  }),
})

export const {
  useLoginMutation,
  useGetUserQuery,
  useUpdateUserMutation,
  useUpdatePasswordMutation,
  useAskResetPasswordMutation,
} = commonApi
