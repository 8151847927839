import cn from 'classnames/bind'
import { useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Spinner } from '../../../components/Spinner'
import { ReceiptIcon } from '../../../components/svg/Receipt'
import { useAppDispatch, useAppSelector } from '../../../store'
import { addAlert } from '../../../store/alerts'
import { selectAuthToken } from '../../../store/auth'
import styles from './index.module.css'

const cx = cn.bind(styles)

type Props = {
  orderUuid: string
}

export const DownloadReceiptPdf = ({ orderUuid }: Props) => {
  const dispatch = useAppDispatch()
  const { t } = useTranslation()
  const ref = useRef<HTMLAnchorElement>(null)
  const [downloading, setDownloading] = useState(false)

  const { current } = ref

  const token = useAppSelector(selectAuthToken)

  const startDownload = async () => {
    if (downloading) {
      return
    }

    try {
      setDownloading(true)

      const response = await fetch(
        `${process.env.REACT_APP_COMMON_BASE_URL}/preserved-documents/nsofacile.it/order/${orderUuid}/receipt`,
        {
          headers: {
            Accept: 'application/pdf',
            Authorization: `Bearer ${token}`,
          },
        },
      )

      if (response.status === 404) {
        dispatch(
          addAlert({ type: 'info', translationId: 'feedback.error.preservationReceiptNotReady' }),
        )
        return
      }

      if (response.status !== 200) {
        dispatch(addAlert({ type: 'error', translationId: 'feedback.error.generic' }))
        return
      }

      const data = await response.blob()
      const url = URL.createObjectURL(data)

      if (current != null) {
        current.href = url
        current.click()
      }
    } catch (error) {
      dispatch(addAlert({ type: 'error', translationId: 'feedback.error.generic' }))
    } finally {
      setDownloading(false)
    }
  }

  return (
    <>
      <a
        ref={ref}
        className={cx('hidden-trigger')}
        href="#/"
        download={`ricevuta_${orderUuid}.pdf`}
      >
        {t('pages.incomingOrders.downloadReceiptPdf')}
      </a>

      {downloading && <Spinner width={20} height={20} />}

      {!downloading && (
        <button
          className={cx('cta')}
          title={t('pages.incomingOrders.downloadReceiptPdf')}
          onClick={startDownload}
        >
          <ReceiptIcon width={20} height={20} />
        </button>
      )}
    </>
  )
}
