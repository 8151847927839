import { createSlice, createSelector, PayloadAction } from '@reduxjs/toolkit'
import type { RootState } from '..'

const DEFAULT_ITEMS_PER_PAGE = 10

export type TableFilters = {
  direction: string
  createdAfter: string
  createdBefore: string
  senderName: string
  orderDateAfter: string
  orderDateBefore: string
  orderNumber: string
}

type TableState = {
  itemsPerPage: number
  currentPage: number
  totalPages: number
  itemDetailsUuid: string | null
  filters: TableFilters
}

type State = {
  incomingOrders: TableState
}

type TableName = keyof State
export type TableFilter = keyof TableFilters

const currentYear = new Date().getFullYear()

export const filtersInitialState = {
  direction: 'incoming',
  createdAfter: new Date(currentYear, 0, 1, 0, 0, 0).toUTCString(),
  createdBefore: new Date(currentYear, 11, 31, 23, 59, 59).toUTCString(),
  senderName: '',
  orderDateAfter: '',
  orderDateBefore: '',
  orderNumber: '',
}

const initialState: State = {
  incomingOrders: {
    itemsPerPage: DEFAULT_ITEMS_PER_PAGE,
    currentPage: 1,
    totalPages: 1,
    itemDetailsUuid: null,
    filters: filtersInitialState,
  },
}

const tablesSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    updateCurrentPage: (state, { payload }: PayloadAction<{ table: TableName; page: number }>) => {
      const { table, page } = payload
      state[table].currentPage = page
    },

    updateTotalPages: (
      state,
      { payload }: PayloadAction<{ table: TableName; totalItems: number }>,
    ) => {
      const { table, totalItems } = payload
      state[table].totalPages = Math.ceil(totalItems / state[table].itemsPerPage)
    },

    updateItemDetailsUuid: (
      state,
      { payload }: PayloadAction<{ table: TableName; uuid: string }>,
    ) => {
      const { table, uuid } = payload
      state[table].itemDetailsUuid = uuid
    },

    resetOrderDetailsUuid: (state, { payload }: PayloadAction<{ table: TableName }>) => {
      const { table } = payload
      state[table].itemDetailsUuid = null
    },

    resetPagination: (state, { payload }: PayloadAction<{ table: TableName }>) => {
      const { table } = payload

      state[table].currentPage = 1
      state[table].totalPages = 1
    },

    updateFilter: (
      state,
      { payload }: PayloadAction<{ table: TableName; filter: TableFilter; value: string }>,
    ) => {
      const { table, filter, value } = payload
      state[table].filters[filter] = value
    },

    resetFilters: (state, { payload }: PayloadAction<{ table: TableName }>) => {
      const { table } = payload

      const filtersChanged =
        JSON.stringify(state[table].filters) !== JSON.stringify(filtersInitialState)

      state[table].filters = filtersInitialState

      if (filtersChanged) {
        state[table].currentPage = 1
        state[table].totalPages = 1
      }
    },
  },
})

export const { reducer } = tablesSlice

export const {
  updateCurrentPage,
  updateTotalPages,
  updateItemDetailsUuid,
  resetOrderDetailsUuid,
  resetPagination,
  updateFilter,
  resetFilters,
} = tablesSlice.actions

export const selectTables = (state: RootState) => state.tablesState

export const selectTable = ({ table }: { table: TableName }) =>
  createSelector([selectTables], (tables) => tables[table])

// Returns the table data formatted, ready to be passed to a query hook (e.g. 'useGetOrdersQuery')
export const selectTableAsQueryParams = ({ table }: { table: TableName }) =>
  createSelector([selectTable({ table })], ({ itemsPerPage, currentPage, filters }) => {
    const {
      direction,
      createdAfter,
      createdBefore,
      senderName,
      orderDateAfter,
      orderDateBefore,
      orderNumber,
    } = filters

    return {
      itemsPerPage,
      'page': currentPage,
      'direction': direction,
      'createdAt[after]': createdAfter,
      'createdAt[before]': createdBefore,
      '~senderName': senderName,
      'documentDate[after]': orderDateAfter,
      'documentDate[before]': orderDateBefore,
      'documentNumber': orderNumber,
    }
  })

export const selectItemsPerPage = ({ table }: { table: TableName }) =>
  createSelector([selectTable({ table })], ({ itemsPerPage }) => itemsPerPage)

export const selectCurrentPage = ({ table }: { table: TableName }) =>
  createSelector([selectTable({ table })], ({ currentPage }) => currentPage)

export const selectTotalPages = ({ table }: { table: TableName }) =>
  createSelector([selectTable({ table })], ({ totalPages }) => totalPages)

export const selectItemDetailsUuid = ({ table }: { table: TableName }) =>
  createSelector([selectTable({ table })], ({ itemDetailsUuid }) => itemDetailsUuid)

export const selectTableFilters = ({ table }: { table: TableName }) =>
  createSelector([selectTable({ table })], ({ filters }) => filters)
