import { createSlice, createSelector, PayloadAction } from '@reduxjs/toolkit'
import type { RootState } from '..'
import { deleteToken, readToken, saveToken } from '../../utils/authTokenStorage'
import { isAuthTokenExpired } from '../../utils/isAuthTokenExpired'

type State = {
  token: string | null
}

const initialState: State = {
  token: readToken(),
}

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    login: (state, { payload }: PayloadAction<{ token: string }>) => {
      saveToken(payload.token)
      state.token = payload.token
    },
    logout: (state) => {
      deleteToken()
      state.token = null
    },
  },
})

export const { reducer } = authSlice

export const { login, logout } = authSlice.actions

const selectAuth = (state: RootState) => state.auth

export const selectAuthToken = createSelector([selectAuth], ({ token }) => token)

export const selectIsLoggedIn = createSelector(
  [selectAuthToken],
  (token) => !isAuthTokenExpired(token),
)
