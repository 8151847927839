import { useEffect } from 'react'
import { useNavigate } from 'react-router'

export const Home = () => {
  const navigate = useNavigate()

  useEffect(() => {
    navigate('/incoming-orders', { replace: true })
  }, [navigate])

  return null
}
