import { filtersInitialState } from '../../../store/tables'
import type { TableFilter, TableFilters } from '../../../store/tables'

export type FormValues = {
  senderName: string
  creationYear: string
  orderDateAfter: string
  orderDateBefore: string
  orderNumber: string
}

export const mapFormValuesToTableFilters = (formValues: FormValues): TableFilters =>
  Object.entries(formValues).reduce((acc, [key, value]) => {
    const formField = key as keyof FormValues

    if (formField === 'creationYear') {
      return {
        ...acc,
        createdAfter: new Date(Number(value), 0, 1, 0, 0, 0).toUTCString(),
        createdBefore: new Date(Number(value), 11, 31, 23, 59, 59).toUTCString(),
      }
    }

    return { ...acc, [key]: value }
  }, filtersInitialState)

export const mapTableFiltersToFormValues = (tableFilters: TableFilters): FormValues =>
  Object.entries(tableFilters)
    .filter(([key]) => key !== 'direction')
    .reduce((acc, [key, value]) => {
      const filter = key as TableFilter

      if (value === undefined) {
        return acc
      }

      if (filter === 'createdAfter' || filter === 'createdBefore') {
        return { ...acc, creationYear: String(new Date(value).getFullYear()) }
      }

      return { ...acc, [key]: value }
    }, {} as FormValues)
