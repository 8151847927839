import { Field, Form, Formik } from 'formik'
import { useTranslation } from 'react-i18next'
import { Button } from '../Button'
import { FormikInput } from '../FormikInput'
import { Arrow } from '../svg/Arrow'
import { FirstPage } from '../svg/FirstPage'
import { LastPage } from '../svg/LastPage'

type FormValues = {
  currentPage: string
}

type Props = {
  current: number
  total: number
  onChange: (newPage: number) => void
}

export const Pagination = ({ current, total, onChange }: Props) => {
  const { t } = useTranslation()

  const initialValues = { currentPage: String(current) }

  const handlePrev = () => {
    if (current > 1) {
      onChange(current - 1)
    }
  }

  const handleNext = () => {
    if (current < total) {
      onChange(current + 1)
    }
  }

  const handleChange = (newPage: number) => {
    if (newPage !== current) {
      onChange(newPage)
    }
  }

  const handleSubmit = ({ currentPage }: FormValues) => {
    const newPage = Number(currentPage)

    if (newPage === 0 || !Number.isInteger(newPage)) {
      return
    }

    handleChange(newPage)
  }

  return (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      <Button
        kind="primary-inverse"
        size="sm"
        onClick={() => handleChange(1)}
        disabled={current === 1}
        title={t('pagination.first')}
        style={{ padding: '0.5rem' }}
      >
        <FirstPage width={24} height={24} />
      </Button>

      <Button
        kind="primary-inverse"
        size="sm"
        onClick={handlePrev}
        disabled={current === 1}
        title={t('pagination.prev')}
        style={{ padding: '0.5rem' }}
      >
        <Arrow direction="left" width={24} height={24} />
      </Button>

      <div style={{ display: 'flex', alignItems: 'center', margin: '0 0.5rem' }}>
        {t('pagination.page')}
        <Formik initialValues={initialValues} onSubmit={handleSubmit} enableReinitialize>
          {() => (
            <Form>
              <Field
                component={FormikInput}
                style={{ width: '3.5rem', padding: '0.5rem 0.75rem', margin: '0 .5rem' }}
                type="text"
                name="currentPage"
                id="currentPage"
              />
            </Form>
          )}
        </Formik>
        {t('pagination.of', { total })}
      </div>

      <Button
        kind="primary-inverse"
        size="sm"
        onClick={handleNext}
        disabled={current === total}
        title={t('pagination.next')}
        style={{ padding: '0.5rem' }}
      >
        <Arrow direction="right" width={24} height={24} />
      </Button>

      <Button
        kind="primary-inverse"
        size="sm"
        onClick={() => handleChange(total)}
        disabled={current === total}
        title={t('pagination.last')}
        style={{ padding: '0.5rem' }}
      >
        <LastPage width={24} height={24} />
      </Button>
    </div>
  )
}
