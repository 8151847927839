import cn from 'classnames/bind'
import { useCallback, useEffect, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { Card } from '../../../components/Card'
import { Spinner } from '../../../components/Spinner'
import { Cross } from '../../../components/svg/Cross'
import { useOrderDetails } from '../../../hooks/useOrderDetails'
import { useAppDispatch, useAppSelector } from '../../../store'
import { resetOrderDetailsUuid, selectItemDetailsUuid } from '../../../store/tables'
import styles from './index.module.css'
import './external.css'

const cx = cn.bind(styles)

export const OrderDetails = () => {
  const dispatch = useAppDispatch()
  const { t } = useTranslation()

  const wrapperRef = useRef<HTMLDivElement>(null)
  const wrapper = wrapperRef.current
  const contentRef = useRef<HTMLDivElement>(null)
  const content = contentRef.current

  const orderUuid = useAppSelector(selectItemDetailsUuid({ table: 'incomingOrders' }))

  const { data, isFetching, hasError } = useOrderDetails(orderUuid)

  const handleClose = useCallback(() => {
    dispatch(resetOrderDetailsUuid({ table: 'incomingOrders' }))
  }, [dispatch])

  useEffect(() => {
    const handleClick = (event: MouseEvent) => {
      if (orderUuid !== null && wrapper !== null && !wrapper.contains(event.target as Node)) {
        handleClose()
      }
    }

    document.addEventListener('mousedown', handleClick)

    return () => {
      document.removeEventListener('mousedown', handleClick)
    }
  }, [orderUuid, wrapper, handleClose])

  useEffect(() => {
    ;(async () => {
      if (data !== null && content !== null) {
        content.innerHTML = ''

        const template = document.createElement('template')
        template.innerHTML = data

        const sections = Array.from(template.content.querySelectorAll('.page-break'))
        sections.forEach((section) => content.appendChild(section))
      }
    })()
  }, [data, content, isFetching])

  if (orderUuid === null) {
    return null
  }

  return (
    <div className={cx('details-overlay')}>
      <div ref={wrapperRef} className={cx('details')}>
        <Card>
          <div className={cx('wrapper')}>
            <Card.Header>
              <div className={cx('header')}>
                <Card.HeaderTitle>{t('pages.incomingOrders.orderDetails.title')}</Card.HeaderTitle>

                <button className={cx('close-cta')} onClick={handleClose}>
                  <Cross width={24} height={24} />
                </button>
              </div>
            </Card.Header>

            <Card.Body>
              {isFetching && (
                <div className={cx('content-loading')}>
                  <Spinner width={30} height={30} />
                </div>
              )}

              {hasError && <div className={cx('content-error')}>{t('feedback.error.generic')}</div>}

              <div
                ref={contentRef}
                id="order-details"
                className={cx({ 'content--hidden': isFetching })}
              />
            </Card.Body>
          </div>
        </Card>
      </div>
    </div>
  )
}
