import cn from 'classnames/bind'
import { ErrorMessage, Field, Form, Formik } from 'formik'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import * as Yup from 'yup'
import { Button } from '../../components/Button'
import { Card } from '../../components/Card'
import { FormikInput } from '../../components/FormikInput'
import { FormLabel } from '../../components/FormLabel'
import { InputErrorMessage } from '../../components/InputErrorMessage'
import { useUpdatePasswordMutation } from '../../services/common'
import { useAppDispatch } from '../../store'
import { addAlert } from '../../store/alerts'
import styles from './index.module.css'

const cx = cn.bind(styles)

const initialValues = { password: '', passwordConfirmation: '' }

type FormValues = typeof initialValues

const fields: {
  name: keyof FormValues
  labelTranslationId: string
  placeholderTranslationId: string
}[] = [
  {
    name: 'password',
    labelTranslationId: 'pages.editPassword.fields.password.label',
    placeholderTranslationId: 'pages.editPassword.fields.password.placeholder',
  },
  {
    name: 'passwordConfirmation',
    labelTranslationId: 'pages.editPassword.fields.passwordConfirmation.label',
    placeholderTranslationId: 'pages.editPassword.fields.passwordConfirmation.placeholder',
  },
]

export const EditPassword = () => {
  const dispatch = useAppDispatch()
  const { t } = useTranslation()
  const [updatePassword, { isLoading }] = useUpdatePasswordMutation()

  const validationSchema = useMemo(
    () =>
      Yup.object({
        password: Yup.string()
          .max(255)
          .matches(
            /^((?=.*[A-z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,}).*)$/,
            t('pages.editPassword.fields.password.validation.regex'),
          )
          .required(t('pages.editPassword.fields.password.validation.required')),
        passwordConfirmation: Yup.string()
          .oneOf(
            [Yup.ref('password'), null],
            t('pages.editPassword.fields.passwordConfirmation.validation.matchesPassword'),
          )
          .required(t('pages.editPassword.fields.passwordConfirmation.validation.required')),
      }),
    [t],
  )

  const handleSubmit = async (values: FormValues) => {
    const response = await updatePassword({ id: 'me', ...values })

    if ('error' in response) {
      const { error } = response

      if ('status' in error && error.status !== 422) {
        dispatch(addAlert({ type: 'error', translationId: 'feedback.error.generic' } as const))
      }
    }

    if ('data' in response) {
      dispatch(
        addAlert({ type: 'success', translationId: 'feedback.success.successfulEditProfile' }),
      )
    }
  }

  return (
    <>
      <Card.Header>
        <Card.HeaderTitle>{t('pages.editPassword.title')}</Card.HeaderTitle>
      </Card.Header>

      <Card.Body>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {({ isSubmitting }) => (
            <Form>
              <div className={cx('form-control-group')}>
                {fields.map(({ name, labelTranslationId, placeholderTranslationId }) => (
                  <div key={name} className={cx('form-control')}>
                    <FormLabel htmlFor={name} title={t(labelTranslationId)} />
                    <Field
                      component={FormikInput}
                      type="password"
                      name={name}
                      id={name}
                      placeholder={t(placeholderTranslationId)}
                    />
                    <ErrorMessage name={name} component={InputErrorMessage} />
                  </div>
                ))}
              </div>

              <div className={cx('form-actions')}>
                <Button kind="primary" size="lg" type="submit" disabled={isLoading}>
                  {t('pages.editPassword.cta')}
                </Button>
              </div>
            </Form>
          )}
        </Formik>
      </Card.Body>
    </>
  )
}
