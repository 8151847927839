const AUTH_TOKEN_KEY = 'authToken'

export const readToken = () => localStorage.getItem(AUTH_TOKEN_KEY)

export const saveToken = (token: string) => {
  try {
    localStorage.setItem(AUTH_TOKEN_KEY, token)
  } catch (err) {}
}

export const deleteToken = () => {
  localStorage.removeItem(AUTH_TOKEN_KEY)
}
