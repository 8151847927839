import { createSlice, createSelector } from '@reduxjs/toolkit'
import type { RootState } from '..'

type State = {
  mobileMenuVisible: boolean
}

const initialState: State = {
  mobileMenuVisible: false,
}

const uiSlice = createSlice({
  name: 'ui',
  initialState,
  reducers: {
    toggleMobileMenu: (state) => {
      state.mobileMenuVisible = !state.mobileMenuVisible
    },
  },
})

export const { reducer } = uiSlice

export const { toggleMobileMenu } = uiSlice.actions

const selectUi = (state: RootState) => state.ui

export const selectIsMobileMenuVisible = createSelector(
  [selectUi],
  ({ mobileMenuVisible }) => mobileMenuVisible,
)
