type Direction = 'top' | 'right' | 'bottom' | 'left'

type Props = {
  width: number
  height: number
  direction: Direction
}

const directionToRotation = (direction: Direction) => {
  switch (direction) {
    case 'top':
      return 90
    case 'right':
      return 180
    case 'bottom':
      return 270
    case 'left':
    default:
      return 0
  }
}

export const Arrow = ({ width, height, direction }: Props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    fill="currentColor"
    viewBox="0 0 24 24"
    style={{ transform: `rotate(${directionToRotation(direction)}deg)` }}
  >
    <path d="M15.41 7.41L14 6l-6 6 6 6 1.41-1.41L10.83 12z"></path>
  </svg>
)
