import cn from 'classnames/bind'
import { format } from 'date-fns'
import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { Spinner } from '../../../components/Spinner'
import { useGetOrdersQuery } from '../../../services/peppol'
import { useAppDispatch, useAppSelector } from '../../../store'
import { addAlert } from '../../../store/alerts'
import { selectTableAsQueryParams, updateTotalPages } from '../../../store/tables'
import { DownloadOrderPdf } from '../DownloadOrderPdf'
import { DownloadReceiptPdf } from '../DownloadReceiptPdf'
import { OrderDetailsTrigger } from '../OrderDetailsTrigger'
import styles from './index.module.css'

const cx = cn.bind(styles)

const incomingOrdersTable = { table: 'incomingOrders' } as const

export const IncomingOrdersTableContent = () => {
  const dispatch = useAppDispatch()
  const { t } = useTranslation()

  const queryParams = useAppSelector(selectTableAsQueryParams(incomingOrdersTable))

  // @ts-expect-error
  const { data, error, isFetching } = useGetOrdersQuery(queryParams)

  const items = data?.['hydra:member']

  useEffect(() => {
    if (error) {
      dispatch(addAlert({ type: 'error', translationId: 'feedback.error.generic' }))
    }
  }, [dispatch, error, t])

  useEffect(() => {
    const totalItems = data?.['hydra:totalItems']

    if (totalItems !== undefined) {
      dispatch(updateTotalPages({ table: 'incomingOrders', totalItems }))
    }
  }, [dispatch, data])

  if (!isFetching && error === undefined && items !== undefined) {
    return (
      <>
        {items.map((item) => (
          <tr key={item.uuid}>
            <td>{format(new Date(item.createdAt), 'dd MMM yyyy HH:mm:ss')}</td>
            <td>{item.sender.name}</td>
            <td>{item.date}</td>
            <td>{item.number}</td>
            <td>
              <OrderDetailsTrigger orderUuid={item.uuid} />
            </td>
            <td>
              <DownloadOrderPdf orderUuid={item.uuid} />
            </td>
            <td>
              <DownloadReceiptPdf orderUuid={item.uuid} />
            </td>
          </tr>
        ))}
      </>
    )
  }

  if (error !== undefined) {
    return (
      <tr>
        <td colSpan={7} className={cx('content-error')}>
          {t('feedback.error.generic')}
        </td>
      </tr>
    )
  }

  return (
    <tr>
      <td colSpan={7} className={cx('content-loading')}>
        <Spinner width={30} height={30} />
      </td>
    </tr>
  )
}
