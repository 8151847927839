import { BaseQueryFn, FetchArgs, fetchBaseQuery, FetchBaseQueryError } from '@reduxjs/toolkit/query'
import type { RootState } from '../store'
import { addAlert } from '../store/alerts'
import { logout, selectAuthToken } from '../store/auth'

export const baseQuery =
  ({
    baseUrl,
  }: {
    baseUrl: string
  }): BaseQueryFn<string | FetchArgs, unknown, FetchBaseQueryError> =>
  async (args, helpers, extraOptions) => {
    const token = selectAuthToken(helpers.getState() as RootState)

    const response = await fetchBaseQuery({
      baseUrl,
      prepareHeaders: (headers, { getState }) => {
        if (token !== null) {
          headers.set('authorization', `Bearer ${token}`)
        }

        return headers
      },
    })(args, helpers, extraOptions)

    // Auth token expired or invalid
    if (response.error !== undefined && response.error.status === 401) {
      if (token !== null) {
        helpers.dispatch(
          addAlert({ type: 'error', translationId: 'feedback.error.sessionExpired' }),
        )
      }

      helpers.dispatch(logout())
    }

    return response
  }
