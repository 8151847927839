import cn from 'classnames/bind'
import { Fragment } from 'react'
import { useTranslation } from 'react-i18next'
import { NavLink } from 'react-router-dom'
import { FileEarmarkArrowUp } from '../../../components/svg/FileEarmarkArrowUp'
import { Key } from '../../../components/svg/Key'
import { PersonBadge } from '../../../components/svg/PersonBadge'
import { Wallet } from '../../../components/svg/Wallet'
import styles from './index.module.css'
import { PeppolCode } from './PeppolCode'

const cx = cn.bind(styles)

const sections = [
  {
    translationId: 'sidebar.sections.orders',
    items: [
      {
        Icon: FileEarmarkArrowUp,
        translationId: 'sidebar.items.incomingOrders',
        path: '/incoming-orders',
      },
    ],
  },
  {
    translationId: 'sidebar.sections.account',
    items: [
      {
        Icon: PersonBadge,
        translationId: 'sidebar.items.updateProfile',
        path: '/account/edit-profile',
      },
      {
        Icon: Key,
        translationId: 'sidebar.items.updatePassword',
        path: '/account/edit-password',
      },
      {
        Icon: Wallet,
        translationId: 'sidebar.items.manageSubscription',
        path: '/account/manage-subscription',
      },
    ],
  },
]

export const SidebarLayoutNavigation = () => {
  const { t } = useTranslation()

  return (
    <>
      <span className={cx('section-title')}>{t('sidebar.sections.peppolCode')}</span>
      <PeppolCode />

      {sections.map(({ translationId, items }) => (
        <Fragment key={translationId}>
          <span className={cx('section-title')}>{t(translationId)}</span>

          <ul className={cx('section-items')}>
            {items.map((item) => (
              <li key={item.path}>
                <NavLink
                  className={({ isActive }) =>
                    cx('section-item', { 'section-item--active': isActive })
                  }
                  to={item.path}
                >
                  <>
                    <span className={cx('section-item-icon')}>
                      <item.Icon width={18} height={18} />
                    </span>

                    {t(item.translationId)}
                  </>
                </NavLink>
              </li>
            ))}
          </ul>
        </Fragment>
      ))}
    </>
  )
}
