import { AssertAuthenticated } from './views/AssertAuthenticated'
import { EditPassword } from './views/EditPassword'
import { EditProfile } from './views/EditProfile'
import { Home } from './views/Home'
import { IncomingOrders } from './views/IncomingOrders'
import { BaseLayout } from './views/BaseLayout'
import { Login } from './views/Login'
import { ManageSubscription } from './views/ManageSubscription'
import { SidebarLayout } from './views/SidebarLayout'
import { ResetPassword } from './views/ResetPassword'

export const routes = [
  {
    path: '/',
    element: <BaseLayout />,
    children: [
      {
        path: '/login',
        element: <Login />,
      },
      {
        path: '/reset-password',
        element: <ResetPassword />,
      },
      {
        path: '/',
        element: <AssertAuthenticated />,
        children: [
          {
            path: '/',
            element: <SidebarLayout />,
            children: [
              {
                path: '/',
                element: <Home />,
              },
              {
                path: '/incoming-orders',
                element: <IncomingOrders />,
              },
              {
                path: '/account/edit-profile',
                element: <EditProfile />,
              },
              {
                path: '/account/edit-password',
                element: <EditPassword />,
              },
              {
                path: '/account/manage-subscription',
                element: <ManageSubscription />,
              },
            ],
          },
        ],
      },
    ],
  },
]
