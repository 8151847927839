import cn from 'classnames/bind'
import { FieldProps } from 'formik'
import styles from './index.module.css'

const cx = cn.bind(styles)

/**
 * Custom date input to change the default date color ("dd/mm/yyyy").
 */
export const FormikDateInput = ({ field, form, ...props }: FieldProps) => {
  const isEmpty = field.value === ''

  return <input {...field} {...props} className={cx('input', { 'input--empty': isEmpty })} />
}
