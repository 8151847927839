import cn from 'classnames/bind'
import styles from './index.module.css'

const cx = cn.bind(styles)

type Props = {
  htmlFor: string
  title: string
}

export const FormLabel = ({ htmlFor, title }: Props) => (
  <label className={cx('label')} htmlFor={htmlFor}>
    {title}
  </label>
)
