import { useRoutes } from 'react-router'
import { routes } from './routes'
import { Alerts } from './views/Alerts'
import './i18n'
import './styles/bootstrap.css'
import './styles/global.css'

export const App = () => (
  <>
    <Alerts />
    {useRoutes(routes)}
  </>
)
