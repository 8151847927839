import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import type { RootState } from '..'

const TIMEOUT = 5000

type AlertType = 'info' | 'error' | 'success'

type Alert = {
  id: string
  type: AlertType
  translationId: string
}

type State = Alert[]

const initialState: State = []

const alertsSlice = createSlice({
  name: 'alerts',
  initialState,
  reducers: {
    add: (state, { payload }: PayloadAction<Alert>) => [...state, payload],
    remove: (state, { payload }: PayloadAction<{ id: string }>) => {
      return state.filter(({ id }) => id !== payload.id)
    },
  },
})

const { actions } = alertsSlice

export const addAlert = createAsyncThunk(
  'alerts/addTimed',
  (data: Pick<Alert, 'type' | 'translationId'>, { dispatch }) => {
    const id = Math.random().toString(36).slice(1)

    dispatch(actions.add({ id, ...data }))

    setTimeout(() => {
      dispatch(actions.remove({ id }))
    }, TIMEOUT)
  },
)

export const { reducer } = alertsSlice

export const selectAlerts = (state: RootState) => state.alerts
