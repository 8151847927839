import cn from 'classnames/bind'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Spinner } from '../../../../components/Spinner'
import { QuestionIcon } from '../../../../components/svg/Question'
import { useGetLegalEntitiesQuery } from '../../../../services/peppol'
import styles from './index.module.css'

const cx = cn.bind(styles)

export const PeppolCode = () => {
  const { t } = useTranslation()
  const { data, isFetching } = useGetLegalEntitiesQuery(undefined)
  const [showTooltip, setShowTooltip] = useState(false)

  if (isFetching || data == null) {
    return (
      <div className={cx('peppol-code')}>
        <Spinner width={15} height={15} />
      </div>
    )
  }

  const peppolCode = data['hydra:member'][0].identifierValue

  return (
    <>
      <div className={cx('peppol-code')}>
        <span className={cx('peppol-code__value')}>{peppolCode}</span>

        <span
          className={cx('peppol-code__info')}
          onMouseEnter={() => setShowTooltip(true)}
          onMouseLeave={() => setShowTooltip(false)}
        >
          <QuestionIcon width={20} height={20} />

          {showTooltip && (
            <p className={cx('peppol-code__tooltip')}>
              {t('sidebar.peppolCodeInfo.1')}
              <br />
              {t('sidebar.peppolCodeInfo.2')}
            </p>
          )}
        </span>
      </div>
    </>
  )
}
