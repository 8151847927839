import cn from 'classnames/bind'
import { ButtonHTMLAttributes, ReactNode, SyntheticEvent } from 'react'
import styles from './index.module.css'

const cx = cn.bind(styles)

type Props = {
  children: ReactNode
  disabled?: boolean
  kind: 'primary' | 'primary-inverse'
  size: 'sm' | 'md' | 'lg'
  type?: 'submit' | 'reset' | 'button'
  onClick?: (event: SyntheticEvent<HTMLButtonElement>) => void
  title?: string
} & Pick<ButtonHTMLAttributes<HTMLButtonElement>, 'style'>

export const Button = ({
  children,
  disabled,
  kind,
  size,
  type = 'button',
  onClick,
  title,
  ...props
}: Props) => {
  return (
    <button
      {...props}
      disabled={disabled}
      className={cx('button', {
        'button--disabled': disabled,
        'button--sm': size === 'sm',
        'button--md': size === 'md',
        'button--lg': size === 'lg',
        'button--primary': kind === 'primary',
        'button--primary-inverse': kind === 'primary-inverse',
      })}
      type={type}
      onClick={onClick}
      title={title}
    >
      {children}
    </button>
  )
}
