import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux'
import { configureStore, ThunkAction, Action } from '@reduxjs/toolkit'
import { commonApi } from '../services/common'
import { peppolApi } from '../services/peppol'
import { reducer as alertsReducer } from './alerts'
import { reducer as authReducer } from './auth'
import { reducer as tablesReducer } from './tables'
import { reducer as uiReducer } from './ui'

export const store = configureStore({
  reducer: {
    alerts: alertsReducer,
    auth: authReducer,
    tablesState: tablesReducer,
    ui: uiReducer,
    [commonApi.reducerPath]: commonApi.reducer,
    [peppolApi.reducerPath]: peppolApi.reducer,
  },
  middleware: (getDefaultMiddlewares) =>
    getDefaultMiddlewares().concat(commonApi.middleware, peppolApi.middleware),
})

export type AppDispatch = typeof store.dispatch
export type RootState = ReturnType<typeof store.getState>
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>

// Use throughout your app instead of plain `useDispatch` and `useSelector`
export const useAppDispatch = () => useDispatch<AppDispatch>()
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector
