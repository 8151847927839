import cn from 'classnames/bind'
import { useTranslation } from 'react-i18next'
import { FileEarmarkText } from '../../../components/svg/FileEarmarkText'
import { useAppDispatch } from '../../../store'
import { updateItemDetailsUuid } from '../../../store/tables'
import styles from './index.module.css'

const cx = cn.bind(styles)

type Props = {
  orderUuid: string
}

export const OrderDetailsTrigger = ({ orderUuid }: Props) => {
  const dispatch = useAppDispatch()
  const { t } = useTranslation()

  const openOrderDetails = () => {
    dispatch(updateItemDetailsUuid({ table: 'incomingOrders', uuid: orderUuid }))
  }

  return (
    <>
      <button
        className={cx('cta')}
        title={t('pages.incomingOrders.orderDetails.title')}
        onClick={openOrderDetails}
      >
        <FileEarmarkText width={20} height={20} />
      </button>
    </>
  )
}
