import cn from 'classnames/bind'
import { ReactNode } from 'react'
import styles from './index.module.css'

const cx = cn.bind(styles)

type Props = {
  children: ReactNode
}

export const Card = ({ children }: Props) => <div className={cx('card')}>{children}</div>

type CardHeaderProps = {
  children: ReactNode
}

const CardHeader = ({ children }: CardHeaderProps) => (
  <div className={cx('card-header')}>{children}</div>
)

type CardHeaderTitleProps = {
  children: ReactNode
}

const CardHeaderTitle = ({ children }: CardHeaderTitleProps) => (
  <div className={cx('card-header-title')}>{children}</div>
)

type CardBodyProps = {
  children: ReactNode
}

const CardBody = ({ children }: CardBodyProps) => <div className={cx('card-body')}>{children}</div>

Card.Header = CardHeader
Card.HeaderTitle = CardHeaderTitle
Card.Body = CardBody
