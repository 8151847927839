import { useEffect } from 'react'
import { useAppSelector } from '../../store'
import { selectAuthToken } from '../../store/auth'

export const ManageSubscription = () => {
  const token = useAppSelector(selectAuthToken)

  useEffect(() => {
    window.location.replace(
      `${process.env.REACT_APP_NSOFACILE_BASE_URL}/customer/manage-subscription?bearer=${token}`,
    )
  }, [token])

  return null
}
